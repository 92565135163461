import Container from "#components/global/container"
import { useLocation } from "@gatsbyjs/reach-router"
import { graphql } from "gatsby"
import * as React from "react"
import { useEffect } from "react"

import Layout from "#components/global/layout"
import { incrementCounter } from "#utils/firebase"
import { WrenchScrewdriverIcon } from "@heroicons/react/24/solid"
import Seo from "../components/global/seo"

const BlogIndex = ({ data }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const projects = data.allMdx.nodes.filter(
    post => post.frontmatter.published !== false
  )

  const location = useLocation()

  useEffect(() => {
    incrementCounter(location, `pages/projects/views`)
  }, [])

  return (
    <Layout siteTitle={{ siteTitle }}>
      <Seo
        title="Projects"
        description="The projects of a tinker"
        tags="projects"
      />
      <Container className="!pt-10 !md:pt-24">
        <div className="py-8 px-4 mx-auto max-w-screen-md text-center lg:py-16 lg:px-12">
          <WrenchScrewdriverIcon className="mx-auto mb-4 w-10 h-10 animate-wiggle text-amber-800 dark:text-amber-600"></WrenchScrewdriverIcon>
          <h1 className="mb-2 text-2xl font-bold tracking-tight leading-none text-stone-900 lg:mb-2 md:text-2xl xl:text-2xl dark:text-white rotate-1">
            Under Development
          </h1>
          <p className="text-sm text-gray-500 dark:text-gray-400 -rotate-1">
            Still working on the projects page. I'll holla at my socials when
            it's done.
          </p>
        </div>
      </Container>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/blog//" } }
    ) {
      nodes {
        excerpt
        fields {
          slug
          readingTime {
            text
          }
        }
        frontmatter {
          date(formatString: "")
          title
          description
          featuredImage {
            childImageSharp {
              resize {
                src
                aspectRatio
              }
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                width: 700
                aspectRatio: 1
              )
            }
          }
          tags
          featuredImageAlt
          published
        }
      }
    }
  }
`
